export const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? '/api'  // Production: use relative path
  : process.env.REACT_APP_API_URL || 'http://localhost:5001/api';  // Development

  console.log('Environment:', process.env.NODE_ENV);
  console.log('API Base URL:', API_BASE_URL);
  
  export const searchVenues = async (searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) return { existing: [], suggestions: [] };
    
    try {
      const response = await fetch(`${API_BASE_URL}/venues/search?query=${encodeURIComponent(searchTerm)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      
      // Transform existing venues with error handling
      const existingVenues = (data.existing || []).map(venue => ({
        label: (
          <div>
            <div className="text-sm font-medium">{venue.venueName || 'Unnamed Venue'}</div>
            <div className="text-xs text-gray-500 truncate">{venue.address || 'Address unavailable'}</div>
          </div>
        ),
        value: {
          ...venue,
          isExisting: true,
          avgCost: venue.stats?.averageCost,
          avgGuests: venue.stats?.guestRange?.max || 0,
          submissions: venue.stats?.count || 0,
          venueName: venue.venueName || 'Unnamed Venue',
          address: venue.address || 'Address unavailable',
          neighborhood: venue.neighborhood || '',
          location: venue.location || ''
        }
      }));
  
      // Transform Google Places suggestions
      const newVenues = (data.suggestions || []).map(venue => ({
        label: (
          <div>
            <div className="text-sm font-medium">{venue.venueName}</div>
            <div className="text-xs text-gray-500 truncate">{venue.address}</div>
          </div>
        ),
        value: {
          ...venue,
          isExisting: false
        }
      }));
  
      return {
        existing: existingVenues.filter(v => v.value.venueName && v.value.placeId),
        suggestions: newVenues.filter(v => v.value.placeId)
      };
    } catch (error) {
      console.error('Error in searchVenues:', error);
      return { existing: [], suggestions: [] };
    }
  };

export const getVenueDetails = async (placeId) => {
  try {
    if (!placeId) throw new Error('Place ID is required');
    const response = await fetch(`${API_BASE_URL}/api/venue/${placeId}`);
    if (!response.ok) throw new Error('Failed to fetch venue details');
    return await response.json();
  } catch (error) {
    console.error('Error fetching venue details:', error);
    throw error;
  }
};

export const formatCurrency = (amount) => {
  if (!amount || isNaN(amount)) return '$0';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};

export const calculateAverages = (submissions) => {
  if (!submissions || submissions.length === 0) return null;
  
  const validSubmissions = submissions.filter(s => 
    !isNaN(s.totalCost) && !isNaN(s.guestCount) && s.totalCost > 0 && s.guestCount > 0
  );

  if (validSubmissions.length === 0) return null;

  const costs = validSubmissions.map(s => Number(s.totalCost));
  const guests = validSubmissions.map(s => Number(s.guestCount));
  
  return {
    avgCost: costs.reduce((a, b) => a + b, 0) / costs.length,
    medianCost: costs.sort((a, b) => a - b)[Math.floor(costs.length / 2)],
    avgGuests: Math.round(guests.reduce((a, b) => a + b, 0) / guests.length),
    avgPerPerson: costs.reduce((a, b) => a + b, 0) / guests.reduce((a, b) => a + b, 0),
    totalSubmissions: validSubmissions.length
  };
};

export const isValidSubmission = (submission) => {
  return (
    submission.venueName &&
    submission.placeId &&
    submission.eventType &&
    submission.guestCount > 0 &&
    submission.totalCost > 0 &&
    submission.eventDate &&
    submission.cancellationPolicy
  );
};