import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/button';
import VenueMap from './venue/VenueMap';
import VenueDetailsModal from './venue/VenueDetailsModal';
import VenueComparisonModal from './venue/VenueComparisonModal';
import Select from 'react-select';
import { MapPin, Filter, ArrowLeftRight, X } from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet';
import { API_BASE_URL } from '../services/venueService';
import { GuestFilter, CostFilter } from './filters/CostAndGuestFilters';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [guestCount, setGuestCount] = useState(75);
  const [maxCost, setMaxCost] = useState(5000);

  // Location options based on data
  const locationOptions = useMemo(() => {
    const cityMap = new Map();
    
    data.forEach(venue => {
      // Use either city or location field
      const city = (venue.city || venue.location)?.trim();
      const neighborhood = venue.neighborhood?.trim();
      
      if (!city) return;
      
      if (!cityMap.has(city)) {
        cityMap.set(city, new Set());
      }
      
      if (neighborhood) {
        cityMap.get(city).add(neighborhood);
      }
    });
  
    // Debug the created options
    console.log('Created location options:', {
      cities: Array.from(cityMap.keys()),
      cityMap: Array.from(cityMap.entries()).map(([city, neighborhoods]) => ({
        city,
        neighborhoods: Array.from(neighborhoods)
      }))
    });
    
    return Array.from(cityMap.entries())
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([city, neighborhoods]) => ({
        value: city,
        label: city,
        neighborhoods: Array.from(neighborhoods)
          .sort()
          .map(n => ({ value: n, label: n }))
      }));
  }, [data]);

  // Get available neighborhoods for selected city
  const availableNeighborhoods = useMemo(() => {
    if (!selectedCity) return [];
    const cityOption = locationOptions.find(opt => opt.value === selectedCity.value);
    return cityOption?.neighborhoods || [];
  }, [selectedCity, locationOptions]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/get-submissions`);
      const submissions = await response.json();
      
      // Deduplicate venues while preserving all submissions
      const venueMap = new Map();
      submissions.forEach(venue => {
        if (!venueMap.has(venue.placeId)) {
          venueMap.set(venue.placeId, {
            ...venue,
            submissions: []
          });
        }
        const existingVenue = venueMap.get(venue.placeId);
        existingVenue.submissions = existingVenue.submissions.concat(venue.submissions || []);
  
        console.log('Venue submissions:', {
          venueName: existingVenue.venueName,
          submissionCount: existingVenue.submissions.length,
          submissions: existingVenue.submissions
        });
        
        // Recalculate stats
        const validCosts = existingVenue.submissions
          .map(s => s.totalCost)
          .filter(cost => !isNaN(cost) && cost > 0);
        
        const validGuestCounts = existingVenue.submissions
          .map(s => s.guestCount)
          .filter(count => !isNaN(count) && count > 0);
        
        existingVenue.stats = {
          averageCost: validCosts.length > 0 
            ? validCosts.reduce((a, b) => a + b, 0) / validCosts.length 
            : null,
          guestRange: validGuestCounts.length > 0 
            ? {
                min: Math.min(...validGuestCounts),
                max: Math.max(...validGuestCounts)
              }
            : { min: null, max: null }
        };
      });
      
      // Sort venues by most recent submission date
      const sortedVenues = Array.from(venueMap.values()).sort((a, b) => {
        const aDate = a.submissions[0]?.eventDate ? new Date(a.submissions[0].eventDate) : new Date(0);
        const bDate = b.submissions[0]?.eventDate ? new Date(b.submissions[0].eventDate) : new Date(0);
        return bDate - aDate;
      });
      
      setData(sortedVenues);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  const handleVenueSelect = (venue) => {
    setSelectedVenue(venue);
    setShowModal(true);
  };

  const handleVenueCompare = (venue) => {
    if (selectedVenues.find(v => v.placeId === venue.placeId)) {
      setSelectedVenues(selectedVenues.filter(v => v.placeId !== venue.placeId));
    } else if (selectedVenues.length < 3) {
      setSelectedVenues([...selectedVenues, venue]);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVenue(null);
  };

  const filteredData = useMemo(() => {
    //Add debug logging
    console.log('Filtering with:', {
      selectedCity: selectedCity?.value,
      selectedNeighborhoods: selectedNeighborhoods.map(n => n.value),
      guestCount,
      maxCost,
      venues: data.map(v => ({
        name: v.venueName,
        city: v.city,
        neighborhood: v.neighborhood,
        maxGuests: v.stats?.guestRange?.max,
        avgCost: v.stats?.averageCost
      }))
    });
  
    return data.filter(venue => {
      // Make sure we're comparing the right properties
      const venueCity = venue.city?.toLowerCase() || venue.location?.toLowerCase();
      const selectedCityValue = selectedCity?.value?.toLowerCase();
  
      // Debug individual venue filtering
      console.log('Checking venue:', {
        name: venue.venueName,
        venueCity,
        selectedCity: selectedCityValue,
        venueNeighborhood: venue.neighborhood,
        selectedNeighborhoods: selectedNeighborhoods.map(n => n.value)
      });
  
      // Filter by city first
      if (selectedCity && !venueCity?.includes(selectedCityValue)) {
        return false;
      }
  
      // Then filter by neighborhoods if any are selected
      // Existing neighborhood filter
    if (selectedNeighborhoods.length > 0) {
      const venueNeighborhood = venue.neighborhood?.toLowerCase();
      const matchingNeighborhood = selectedNeighborhoods.some(n => 
        n.value.toLowerCase() === venueNeighborhood
      );
      if (!matchingNeighborhood) {
        return false;
      }
    }

    // New guest count filter
    const maxVenueGuests = venue.stats?.guestRange?.max || 0;
    if (maxVenueGuests > guestCount) {
      return false;
    }

    // New cost filter
    const venueCost = venue.stats?.averageCost || 0;
    if (venueCost > maxCost) {
      return false;
    }

    return true;
  });
}, [data, selectedCity, selectedNeighborhoods, guestCount, maxCost]);

  if (loading) {
    return (
      <Card>
        <CardContent className="flex items-center justify-center h-64">
          <div className="animate-pulse">Loading venues...</div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardContent className="text-red-500">Error: {error}</CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6 p-4 md:p-6">
      {/* Map Section */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle>Venue Map</CardTitle>
          <div className="flex gap-2">
            <Sheet open={showFilters} onOpenChange={setShowFilters}>
              <SheetTrigger asChild>
                <Button variant="outline" size="sm" className="md:hidden">
                  <Filter className="h-4 w-4" />
                </Button>
              </SheetTrigger>
              <SheetContent side="right" className="w-[300px] sm:w-[400px]">
                <SheetHeader>
                  <SheetTitle>Filters</SheetTitle>
                </SheetHeader>
                <div className="py-4 space-y-6">
                  <LocationFilters
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    selectedNeighborhoods={selectedNeighborhoods}
                    setSelectedNeighborhoods={setSelectedNeighborhoods}
                    locationOptions={locationOptions}
                    availableNeighborhoods={availableNeighborhoods}
                  />
                  
                  <GuestFilter
                    value={guestCount}
                    onChange={setGuestCount}
                    maxGuests={75}
                  />
                  
                  <CostFilter
                    value={maxCost}
                    onChange={setMaxCost}
                    guestCount={guestCount}
                  />
                </div>
              </SheetContent>
            </Sheet>
          </div>
        </CardHeader>
        <CardContent>
          <VenueMap 
            venues={filteredData}
            onVenueSelect={handleVenueSelect}
            selectedVenue={selectedVenue}
          />
        </CardContent>
      </Card>

      {/* Filters & Venue List */}
        <div className="flex flex-col md:flex-row gap-6">
          {/* Desktop Filters */}
          <Card className="hidden md:block w-64 shrink-0">
  <CardHeader>
    <CardTitle>Filters</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <LocationFilters
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            selectedNeighborhoods={selectedNeighborhoods}
            setSelectedNeighborhoods={setSelectedNeighborhoods}
            locationOptions={locationOptions}
            availableNeighborhoods={availableNeighborhoods}
          />
          
          <GuestFilter
            value={guestCount}
            onChange={setGuestCount}
            maxGuests={75}
          />
          
          <CostFilter
            value={maxCost}
            onChange={setMaxCost}
            guestCount={guestCount}
          />
        </div>
      </CardContent>
    </Card>

          {/* Venue List */}
  <Card className="flex-1">
    <CardHeader>
      <div className="flex justify-between items-center">
        <CardTitle>Venue List</CardTitle>
        {selectedVenues.length > 0 && (
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowCompareModal(true)}
          >
            <ArrowLeftRight className="h-4 w-4 mr-2" />
            Compare ({selectedVenues.length})
          </Button>
        )}
      </div>
    </CardHeader>
    <CardContent>
      {/* Mobile Card View */}
      <div className="block md:hidden">
        <div className="grid gap-4">
          {filteredData.map((venue) => {
            const stats = venue.stats || {};
            const hasData = stats.averageCost != null && stats.guestRange?.min != null;
            const isSelected = selectedVenues.some(v => v.placeId === venue.placeId);
            
            return (
              <Card
                key={venue.placeId}
                className={`cursor-pointer transition-colors ${
                  selectedVenue?.placeId === venue.placeId ? 'bg-blue-50' : ''
                }`}
              >
                <CardContent className="p-4">
                  <div className="flex justify-between items-start">
                    <div onClick={() => handleVenueSelect(venue)}>
                      <h3 className="font-medium">{venue.venueName}</h3>
                      <p className="text-sm text-gray-500">
                        {venue.neighborhood || venue.location || '-'}
                      </p>
                      <p className="text-xs text-blue-600 mt-1 hover:underline cursor-pointer">
                        Based on {venue.submissions.length} submission{venue.submissions.length !== 1 ? 's' : ''}
                      </p>
                    </div>
                    <Button
                      variant={isSelected ? "secondary" : "ghost"}
                      size="sm"
                      onClick={() => handleVenueCompare(venue)}
                      disabled={selectedVenues.length >= 3 && !isSelected}
                    >
                      <ArrowLeftRight className="h-4 w-4" />
                    </Button>
                  </div>

                  {hasData && (
                    <div className="mt-4 grid grid-cols-2 gap-4">
                      <div>
                        <div className="text-xs text-gray-500">Average Group Size</div>
                        <div className="text-sm font-medium">
                          {stats.guestRange?.min === stats.guestRange?.max
                            ? `${stats.guestRange.min} guests`
                            : `${stats.guestRange.min}-${stats.guestRange.max} guests`}
                        </div>
                      </div>
                      <div>
                        <div className="text-xs text-gray-500">Average Cost</div>
                        <div className="text-sm font-medium">
                          ${Math.round(stats.averageCost).toLocaleString()}
                        </div>
                        <div className="text-xs text-gray-500">
                          ${Math.round(stats.averageCost / stats.guestRange.max).toLocaleString()} per person
                        </div>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>

    {/* Desktop Table View */}
    <div className="hidden md:block">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="text-left p-4">Venue</th>
            <th className="text-left p-4">Neighborhood</th>
            <th className="text-right p-4">Average Cost</th>
            <th className="text-right p-4">Average Group Size</th>
            <th className="w-[50px]"></th>
          </tr>
        </thead>
        <tbody>
                  {filteredData.map((venue) => {
                    const stats = venue.stats || {};
                    const hasData = stats.averageCost != null && stats.guestRange?.min != null;
                    const isSelected = selectedVenues.some(v => v.placeId === venue.placeId);
                    
                    return (
                      <tr 
                        key={venue.placeId}
                        className={`border-b hover:bg-gray-50 cursor-pointer
                          ${selectedVenue?.placeId === venue.placeId ? 'bg-blue-50' : ''}`}
                      >
                        <td className="p-4" onClick={() => handleVenueSelect(venue)}>
                          <div>
                            <div className="font-medium">{venue.venueName}</div>
                            {venue.submissions?.length > 0 && (
                              <div className="text-xs text-gray-500">
                                {venue.submissions.length} submission{venue.submissions.length > 1 ? 's' : ''}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="p-4" onClick={() => handleVenueSelect(venue)}>
                          {venue.neighborhood || venue.location || '-'}
                        </td>
                        <td className="p-4 text-right" onClick={() => handleVenueSelect(venue)}>
                          {hasData ? (
                            <div>
                              <div className="font-medium">
                                ${Math.round(stats.averageCost).toLocaleString()}
                              </div>
                              <div className="text-xs text-gray-500">
                                ${Math.round(stats.averageCost / stats.guestRange.max).toLocaleString()} per person
                              </div>
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="p-4 text-right" onClick={() => handleVenueSelect(venue)}>
                          {stats.guestRange?.min != null ? (
                            <div>
                              <div className="font-medium">
                                {stats.guestRange.min === stats.guestRange.max ? 
                                  `${stats.guestRange.min} guests` :
                                  `${stats.guestRange.min}-${stats.guestRange.max} guests`}
                              </div>
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="p-4 text-center">
                          <Button
                            variant={isSelected ? "secondary" : "ghost"}
                            size="sm"
                            onClick={() => handleVenueCompare(venue)}
                            disabled={selectedVenues.length >= 3 && !isSelected}
                          >
                            <ArrowLeftRight className="h-4 w-4" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Modals */}
      <VenueDetailsModal 
        show={showModal}
        onHide={handleCloseModal}
        venue={selectedVenue}
      />
      
      <VenueComparisonModal
        show={showCompareModal}
        onHide={() => setShowCompareModal(false)}
        venues={selectedVenues}
        onClearVenue={(venue) => handleVenueCompare(venue)}
        onVenueSelect={(venue) => {
          setShowCompareModal(false);  // Close comparison modal
          handleVenueSelect(venue);    // Open venue details modal
        }}
      />
    </div>
  );
};

  // Helper component for location filters
  const LocationFilters = ({
    selectedCity,
    setSelectedCity,
    selectedNeighborhoods,
    setSelectedNeighborhoods,
    locationOptions,
    availableNeighborhoods
  }) => {
    // Add debug logging
    console.log('LocationFilters render:', {
      selectedCity,
      selectedNeighborhoods,
      availableNeighborhoods
    });
  
    return (
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            City
          </label>
          <Select
            value={selectedCity}
            onChange={(option) => {
              console.log('City selected:', option);
              setSelectedCity(option);
              setSelectedNeighborhoods([]); // Reset neighborhoods when city changes
            }}
            options={locationOptions}
            isClearable
            placeholder="Select a city..."
            className="basic-select"
            classNamePrefix="select"
            styles={{
              control: (base) => ({
                ...base,
                minHeight: '42px'
              }),
              menu: (base) => ({
                ...base,
                zIndex: 50
              })
            }}
          />
        </div>
  
        {selectedCity && availableNeighborhoods.length > 0 && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Neighborhoods in {selectedCity.label}
            </label>
            <Select
              isMulti
              value={selectedNeighborhoods}
              onChange={(options) => {
                console.log('Neighborhoods selected:', options);
                setSelectedNeighborhoods(options || []);
              }}
              options={availableNeighborhoods}
              placeholder="Select neighborhoods..."
              className="basic-multi-select"
              classNamePrefix="select"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '42px'
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50
                })
              }}
            />
          </div>
        )}
  
        {selectedCity && selectedNeighborhoods.length > 0 && (
          <div className="text-sm text-gray-500 pt-2">
            Showing venues in {selectedNeighborhoods.length === 1 ? 'one neighborhood' : `${selectedNeighborhoods.length} neighborhoods`} of {selectedCity.label}
          </div>
        )}
      </div>
    );
  };

export default DataTable;