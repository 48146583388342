import React, { useMemo } from 'react';

const EventTimesHeatmap = ({ submissions }) => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const timeSlots = ['Day', 'Evening'];
  
  const heatmapData = useMemo(() => {
    const counts = {};
    daysOfWeek.forEach(day => {
      counts[day] = { 'Day': 0, 'Evening': 0 };
    });

    submissions?.forEach(sub => {
      const eventDate = new Date(sub.eventDate);
      const dayIndex = eventDate.getDay() === 0 ? 6 : eventDate.getDay() - 1;
      const day = daysOfWeek[dayIndex];
      const hour = parseInt(sub.eventStartTime?.split(':')[0]) || 12;
      const timeSlot = hour >= 17 ? 'Evening' : 'Day';
      counts[day][timeSlot]++;
    });

    const maxCount = Math.max(
      ...daysOfWeek.flatMap(day => Object.values(counts[day]))
    );

    return { counts, maxCount };
  }, [submissions]);

  const getColorIntensity = (count, maxCount) => {
    if (maxCount === 0) return 'rgba(59, 130, 246, 0.1)';
    const intensity = Math.max(0.1, count / maxCount);
    return `rgba(59, 130, 246, ${intensity})`;
  };

  if (!submissions?.length) return null;

  return (
    <div className="space-y-2">
      <div className="relative">
        {/* Header row */}
        <div className="grid grid-cols-7 gap-1 mb-2 ml-16 md:ml-20">
          {daysOfWeek.map(day => (
            <div key={day} className="text-sm font-medium text-center">
              {day}
            </div>
          ))}
        </div>

        {/* Time slots and cells */}
        <div className="space-y-2">
          {timeSlots.map(timeSlot => (
            <div key={timeSlot} className="flex items-center">
              <div className="absolute left-0 text-sm font-medium text-right pr-2 md:pr-4 w-16 md:w-20">
                {timeSlot}
              </div>
              <div className="grid grid-cols-7 gap-1 flex-1 ml-16 md:ml-20">
                {daysOfWeek.map(day => (
                  <div
                    key={`${day}-${timeSlot}`}
                    className="aspect-square"
                    style={{
                      backgroundColor: getColorIntensity(
                        heatmapData.counts[day][timeSlot],
                        heatmapData.maxCount
                      ),
                      borderRadius: '4px',
                      minHeight: '32px',
                      maxHeight: '48px'
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="text-xs text-center text-gray-500 mt-4">
        Darker color indicates more events
      </div>
    </div>
  );
};

export default EventTimesHeatmap;