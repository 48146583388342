import React, { useEffect, useRef, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Button } from '../ui/button';
import { MapPin } from 'lucide-react';

// Custom marker setup
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Map bounds adjuster component
const MapBoundsAdjuster = ({ venues, selectedVenue }) => {
  const map = useRef(useMap());
  
  const findNearbyVenues = (position, venues) => {
    const { latitude, longitude } = position.coords;
    const userLocation = [latitude, longitude];
    
    // Calculate roughly 15 miles in degrees (approximate)
    const milesInDegrees = 15 / 69; // 69 miles per degree of latitude
    
    // Find venues within radius
    const validVenues = venues.filter(venue => {
      if (!venue.lat || !venue.lng) return false;
      const distance = Math.sqrt(
        Math.pow(venue.lat - latitude, 2) + 
        Math.pow(venue.lng - longitude, 2)
      );
      return distance < milesInDegrees;
    });
    
    if (validVenues.length > 0) {
      // Create bounds that include user location and nearby venues
      const bounds = L.latLngBounds([userLocation]);
      validVenues.forEach(venue => {
        bounds.extend([Number(venue.lat), Number(venue.lng)]);
      });
      
      map.current.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 13
      });
    } else {
      // If no venues nearby, center on user location with wider zoom
      map.current.setView(userLocation, 11);
    }
  };
  
  useEffect(() => {
    const validVenues = venues.filter(venue => 
      venue.lat && venue.lng && 
      !isNaN(venue.lat) && !isNaN(venue.lng)
    );
    
    if (validVenues.length > 0) {
      const bounds = L.latLngBounds(
        validVenues.map(venue => [Number(venue.lat), Number(venue.lng)])
      );
      
      if (bounds.isValid()) {
        map.current.fitBounds(bounds, { padding: [50, 50] });
      }
    }
  }, [venues]);

  // Original bounds adjustment code remains...
  useEffect(() => {
    const validVenues = venues.filter(venue => 
      venue.lat && venue.lng && 
      !isNaN(venue.lat) && !isNaN(venue.lng)
    );
    
    if (validVenues.length > 0) {
      const bounds = L.latLngBounds(
        validVenues.map(venue => [Number(venue.lat), Number(venue.lng)])
      );
      
      if (bounds.isValid()) {
        map.current.fitBounds(bounds, { padding: [50, 50] });
      }
    }
  }, [venues]);

  // Selected venue handling...
  useEffect(() => {
    if (selectedVenue?.lat && selectedVenue?.lng) {
      map.current.flyTo(
        [Number(selectedVenue.lat), Number(selectedVenue.lng)],
        16,
        { duration: 1.5, easeLinearity: 0.25 }
      );
    }
  }, [selectedVenue]);

  return null;
};

const VenueMap = ({ venues = [], onVenueSelect, selectedVenue = null }) => {
  const mapRef = useRef(null);

  const handleLocationClick = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const mapBounds = mapRef.current;
          if (mapBounds) {
            const userLocation = [position.coords.latitude, position.coords.longitude];
            
            // Find venues within 15 miles
            const milesInDegrees = 15 / 69;
            const nearbyVenues = venues.filter(venue => {
              if (!venue.lat || !venue.lng) return false;
              const distance = Math.sqrt(
                Math.pow(venue.lat - position.coords.latitude, 2) + 
                Math.pow(venue.lng - position.coords.longitude, 2)
              );
              return distance < milesInDegrees;
            });
            
            if (nearbyVenues.length > 0) {
              const bounds = L.latLngBounds([userLocation]);
              nearbyVenues.forEach(venue => {
                bounds.extend([Number(venue.lat), Number(venue.lng)]);
              });
              
              mapBounds.fitBounds(bounds, {
                padding: [50, 50],
                maxZoom: 13
              });
            } else {
              mapBounds.setView(userLocation, 11);
            }
          }
        },
        (error) => {
          console.error('Geolocation error:', error);
          // Handle error - perhaps show a toast notification
        }
      );
    }
  };

  const getCategoryIcon = (category) => {
    const icons = {
      bar: '🍸',
      restaurant: '🍴',
      venue: '🎟️',
      default: '📍'
    };
    return icons[category?.toLowerCase()] || icons.default;
  };

  const createCustomIcon = useMemo(() => (venue) => {
    const isSelected = venue.placeId === selectedVenue?.placeId;
    return L.divIcon({
      className: 'custom-venue-marker',
      html: `
        <div class="venue-marker-icon ${isSelected ? 'selected' : ''}" 
             style="
          background-color: white;
          border: 2px solid ${isSelected ? '#2563eb' : '#4a90e2'};
          border-radius: 50%;
          width: ${isSelected ? '36px' : '30px'};
          height: ${isSelected ? '36px' : '30px'};
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: ${isSelected ? '18px' : '16px'};
          box-shadow: 0 2px 4px rgba(0,0,0,0.2);
          cursor: pointer;
          transform: ${isSelected ? 'scale(1.1)' : 'scale(1)'};
          transition: all 0.3s ease;
        ">
          ${getCategoryIcon(venue.category)}
        </div>
        ${isSelected ? `
          <div class="venue-tooltip" style="
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            background: white;
            padding: 4px 8px;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            white-space: nowrap;
            font-size: 12px;
            pointer-events: none;
          ">
            ${venue.venueName}
          </div>
        ` : ''}
      `,
      iconSize: [isSelected ? 36 : 30, isSelected ? 36 : 30],
      iconAnchor: [isSelected ? 18 : 15, isSelected ? 36 : 30]
    });
  }, [selectedVenue]);


  return (
    <div className="map-container relative rounded-lg overflow-hidden shadow-lg">
      {/* Mobile View Controls */}
      <div className="absolute top-4 right-4 z-10 flex flex-col gap-2">
      <Button 
          onClick={handleLocationClick}
          className="bg-white hover:bg-gray-50 shadow-md"
          size="icon"
          variant="outline"
          title="Find venues near me"
        >
          <MapPin className="h-4 w-4" />
        </Button>
        {selectedVenue && (
          <Button
            onClick={() => mapRef.current?.flyTo([selectedVenue.lat, selectedVenue.lng], 16)}
            className="bg-white hover:bg-gray-50 shadow-md"
            size="icon"
            variant="outline"
            title="Center on selected venue"
          >
            🎯
          </Button>
        )}
      </div>

      <MapContainer
        center={[40.7128, -74.0060]}
        zoom={12}
        style={{ 
          height: '50vh',
          width: '100%',
          minHeight: '300px',
          maxHeight: '600px'
        }}
        ref={mapRef}
        className="z-0"
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        
        <MapBoundsAdjuster venues={venues} selectedVenue={selectedVenue} />
        
        <MarkerClusterGroup
          chunkedLoading
          maxClusterRadius={50}
          spiderfyOnMaxZoom={true}
          showCoverageOnHover={false}
          zoomToBoundsOnClick={true}
        >
          {venues
            .filter(venue => venue.lat && venue.lng && !isNaN(venue.lat) && !isNaN(venue.lng))
            .map(venue => (
              <Marker
                key={venue.placeId}
                position={[Number(venue.lat), Number(venue.lng)]}
                icon={createCustomIcon(venue)}
                eventHandlers={{
                  click: () => onVenueSelect(venue)
                }}
              >
                <Popup>
                  <div className="venue-popup p-2 min-w-[200px]">
                    <h3 className="text-lg font-semibold mb-1">{venue.venueName}</h3>
                    <p className="text-sm text-gray-600 mb-1">
                      {venue.neighborhood || venue.location}
                    </p>
                    <div className="mt-2">
                      {venue.stats?.averageCost ? (
                        <p className="text-sm">
                          <span className="font-medium">
                            ${Math.round(venue.stats.averageCost).toLocaleString()}
                          </span>
                          <span className="text-gray-500">
                            {' '}avg. for {venue.stats.guestRange.min === venue.stats.guestRange.max 
                              ? `${venue.stats.guestRange.min} guests`
                              : `${venue.stats.guestRange.min}-${venue.stats.guestRange.max} guests`
                            }
                          </span>
                        </p>
                      ) : (
                        <p className="text-sm text-gray-500">No cost data available</p>
                      )}
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onVenueSelect(venue);
                      }}
                      className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                    >
                      View Details <span className="ml-1">→</span>
                    </button>
                  </div>
                </Popup>
              </Marker>
            ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default VenueMap;