import React, { createContext, useState, useContext } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    eventType: '',
    eventTypeOther: '',
    dateTime: '',
    location: '',
    venueName: '',
    venueAddress: '',
    guestCount: '',
    duration: '',
    totalCost: '',
    costBreakdown: '',
    minimumSpend: '',
    depositAmount: '',
    bookingLeadTime: '',
    cancellationPolicy: '',
    paymentPolicy: '',
    included: '',
    additionalNotes: ''
  });

  const updateFormData = (newData) => {
    setFormData(prevData => ({ ...prevData, ...newData }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);