import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { X, MinusCircle } from 'lucide-react';
import { motion } from 'framer-motion';

const VenueComparisonModal = ({ show, onHide, venues, onClearVenue, onVenueSelect }) => {
  if (!show) return null;

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent className="max-w-4xl h-[90vh] md:h-auto overflow-hidden">
        <DialogHeader className="relative sticky top-0 bg-white z-10 pb-4 border-b">
          <DialogTitle>Compare Venues</DialogTitle>
        </DialogHeader>
        
        <div className="overflow-y-auto py-4 -mx-6 px-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {venues.map((venue, index) => (
              <motion.div
                key={venue.placeId}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Card 
                  className="relative cursor-pointer hover:shadow-md transition-shadow active:scale-95 touch-manipulation"
                  onClick={() => {
                    if (onVenueSelect) {
                      onVenueSelect(venue);
                    }
                  }}
                >
                  <CardHeader className="pb-2">
                    <CardTitle className="text-lg pr-8">{venue.venueName}</CardTitle>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onClearVenue(venue);
                      }}
                      className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700 touch-manipulation"
                      aria-label="Remove venue from comparison"
                    >
                      <MinusCircle className="h-5 w-5" />
                    </button>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <dl className="space-y-3">
                      <div className="flex justify-between items-start">
                        <dt className="text-sm font-medium text-gray-500">Location</dt>
                        <dd className="text-right">{venue.neighborhood || venue.location}</dd>
                      </div>
                      {venue.stats?.averageCost && (
                        <div className="flex justify-between items-start">
                          <dt className="text-sm font-medium text-gray-500">Average Cost</dt>
                          <dd className="text-right font-medium">
                            ${Math.round(venue.stats.averageCost).toLocaleString()}
                          </dd>
                        </div>
                      )}
                      {venue.stats?.guestRange && (
                        <div className="flex justify-between items-start">
                          <dt className="text-sm font-medium text-gray-500">Capacity</dt>
                          <dd className="text-right">
                            {venue.stats.guestRange.min === venue.stats.guestRange.max
                              ? venue.stats.guestRange.min
                              : `${venue.stats.guestRange.min}-${venue.stats.guestRange.max}`} guests
                          </dd>
                        </div>
                      )}
                      <div className="flex justify-between items-start">
                        <dt className="text-sm font-medium text-gray-500">Submissions</dt>
                        <dd className="text-right">{venue.submissions?.length || 0}</dd>
                      </div>
                    </dl>
                    
                    <div className="pt-2 text-sm text-blue-600 hover:text-blue-800">
                      Tap for details →
                    </div>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VenueComparisonModal;