import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FormProvider } from './context/FormContext';
import { useLoadScript } from '@react-google-maps/api';
import Header from './components/Header';
import DataTable from './components/DataTable';
import SubmissionForm from './components/SubmissionForm';
import AboutPage from './components/AboutPage';

const libraries = ['places'];

function App() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (loadError) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        Error loading maps
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        Loading maps...
      </div>
    );
  }

  return (
    <FormProvider>
      <Router>
        <div className="min-h-screen bg-background">
          <Header />
          <main className="container mx-auto px-4 py-8">
            <Routes>
              <Route path="/" element={<DataTable />} />
              <Route path="/data" element={<DataTable />} />
              <Route path="/submit" element={<SubmissionForm />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </main>
        </div>
      </Router>
    </FormProvider>
  );
}

export default App;