export const EVENT_CATEGORIES = {
    PRIVATE_DINING: {
      value: 'private_dining',
      label: 'Private Room',
      description: 'Fully private dining space',
      examples: 'Birthday dinners, Anniversary celebrations, Family gatherings'
    },
    SEMI_PRIVATE: {
      value: 'semi_private',
      label: 'Semi-Private Space',
      description: 'Reserved section with partial privacy',
      examples: 'Friend gatherings, Small celebrations, Group dinners'
    },
    LARGE_TABLE: {
      value: 'large_table',
      label: 'Large Table',
      description: 'Reserved tables in main dining room',
      examples: 'Group dinners, Casual meetups, Get-togethers'
    },
    OUTDOOR: {
      value: 'outdoor',
      label: 'Outdoor Space',
      description: 'Patio, Rooftop, or Garden space',
      examples: 'Summer parties, Birthday celebrations, Seasonal gatherings'
    }
  };
  
  export const getEventCategoryDetails = (categoryValue) => {
    const category = Object.values(EVENT_CATEGORIES).find(cat => cat.value === categoryValue);
    return category || null;
  };
  
  export const formatEventType = (type) => {
    const category = Object.values(EVENT_CATEGORIES).find(cat => cat.value === type);
    if (category) return category.label;
    
    // Fallback formatting for types not in the categories
    return type.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };