import React from 'react';
import { Card } from '../ui/card';

export const GuestFilter = ({ value, onChange, maxGuests = 75 }) => {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Guest Count
      </label>
      <div className="pt-1">
        <input
          type="range"
          min={0}
          max={maxGuests}
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          className="w-full"
        />
        <div className="flex justify-between text-xs text-gray-500 mt-1">
          <span>0</span>
          <span>Up to {value} guests</span>
        </div>
      </div>
    </div>
  );
};

export const CostFilter = ({ value, onChange, guestCount, maxCost = 10000 }) => {
  const perPersonCost = guestCount > 0 ? Math.round(value / guestCount) : 0;
  
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Maximum Total Cost
      </label>
      <div className="pt-1">
        <input
          type="range"
          min={0}
          max={maxCost}
          step={100}
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          className="w-full"
        />
        <div className="flex justify-between text-xs text-gray-500 mt-1">
          <span>$0</span>
          <span>${value.toLocaleString()}</span>
        </div>
        {guestCount > 0 && (
          <div className="text-xs text-gray-500 mt-1 text-right">
            ~${perPersonCost.toLocaleString()} per person
          </div>
        )}
      </div>
    </div>
  );
};