import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Card, CardContent } from '../ui/card';
import {
  LineChart, Line, BarChart, Bar, XAxis, YAxis,
  CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { ExternalLink, Phone } from 'lucide-react';
import { formatEventType } from '../../constants/eventCategories';
import { BoxPlot, CostHeatmap, EventTimesHeatmap, LeadTimeChart } from '../visualizations';

const MIN_SUBMISSIONS_FOR_CHARTS = 3;
const MIN_SUBMISSIONS_FOR_BOXPLOT = 5;

const VenueDetailsModal = ({ show, onHide, venue }) => {
  if (!venue) return null;

  const submissionCount = venue.submissions?.length || 0;
  const hasEnoughDataForCharts = submissionCount >= MIN_SUBMISSIONS_FOR_CHARTS;
  const hasEnoughDataForBoxPlot = submissionCount >= MIN_SUBMISSIONS_FOR_BOXPLOT;

  // Process submission data for visualizations
  const processSubmissionsData = () => {
    if (!venue.submissions || venue.submissions.length === 0) {
      return { costData: [], costPerPersonData: [] };
    }

    // Group by guest count ranges
    const groupedData = venue.submissions.reduce((acc, sub) => {
      const guestRange = Math.floor(sub.guestCount / 5) * 5;
      const rangeKey = `${guestRange}-${guestRange + 4}`;
      
      if (!acc[rangeKey]) {
        acc[rangeKey] = {
          range: rangeKey,
          costs: [],
          totalCost: 0,
          count: 0
        };
      }
      
      acc[rangeKey].costs.push(sub.totalCost);
      acc[rangeKey].totalCost += sub.totalCost;
      acc[rangeKey].count += 1;
      
      return acc;
    }, {});

    const costData = Object.entries(groupedData).map(([range, data]) => ({
      name: `${range} guests`,
      'Min Cost': Math.min(...data.costs),
      'Average Cost': Math.round(data.totalCost / data.count),
      'Max Cost': Math.max(...data.costs)
    }));

    // Calculate cost per person trend
    const costPerPersonData = venue.submissions
      .map(sub => ({
        name: `${sub.guestCount} guests`,
        'Cost per Person': Math.round(sub.totalCost / sub.guestCount),
        guestCount: sub.guestCount,
        date: new Date(sub.eventDate)
      }))
      .sort((a, b) => a.guestCount - b.guestCount);

    return { costData, costPerPersonData };
  };

  const { costData, costPerPersonData } = processSubmissionsData();

  return (
    <Dialog open={show} onOpenChange={onHide}>
      <DialogContent className="max-w-4xl h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex flex-col">
            <span className="text-2xl font-bold">{venue.venueName}</span>
            <span className="text-sm text-muted-foreground">
              {venue.neighborhood} • {venue.category}
            </span>
            {submissionCount > 0 && (
              <span className="text-xs text-muted-foreground mt-1">
                Based on {submissionCount} submission{submissionCount > 1 ? 's' : ''}
              </span>
            )}
          </DialogTitle>
        </DialogHeader>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Quick Details */}
          <div className="md:col-span-1 space-y-6">
            <Card>
              <CardContent className="pt-6">
                <h3 className="text-lg font-semibold mb-4">Quick Details</h3>
                <dl className="space-y-2">
                  <div>
                    <dt className="text-sm font-medium text-muted-foreground">Location</dt>
                    <dd className="text-sm">{venue.location}</dd>
                  </div>
                  {venue.stats?.averageCost && (
                    <div>
                      <dt className="text-sm font-medium text-muted-foreground">Average Cost</dt>
                      <dd className="text-sm">${Math.round(venue.stats.averageCost).toLocaleString()}</dd>
                    </div>
                  )}
                  {venue.stats?.guestRange && (
                    <div>
                      <dt className="text-sm font-medium text-muted-foreground">Typical Group Size</dt>
                      <dd className="text-sm">
                        {venue.stats.guestRange.min === venue.stats.guestRange.max
                          ? venue.stats.guestRange.min
                          : `${venue.stats.guestRange.min}-${venue.stats.guestRange.max}`} guests
                      </dd>
                    </div>
                  )}
                  {venue.phone && (
                    <div className="flex items-center gap-2">
                      <Phone className="h-4 w-4" />
                      <a 
                        href={`tel:${venue.phone}`}
                        className="text-sm text-blue-600 hover:text-blue-800"
                      >
                        {venue.phone}
                      </a>
                    </div>
                  )}
                </dl>
              </CardContent>
            </Card>

            {venue.submissions?.length > 0 && (
              <Card>
                <CardContent className="pt-6">
                  <h3 className="text-lg font-semibold mb-4">Recent Events</h3>
                  <div className="space-y-3">
                    {venue.submissions.slice(0, 3).map((sub, index) => (
                      <div key={index} className="text-sm">
                        <div className="font-medium">{formatEventType(sub.eventType)}</div>
                        <div className="text-muted-foreground">
                          {sub.guestCount} guests • ${sub.totalCost.toLocaleString()}
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            )}
          </div>

          {/* Charts */}
          <div className="md:col-span-2 space-y-6">
            {hasEnoughDataForCharts ? (
              <>
                <Card>
                  <CardContent className="pt-6">
                    <h3 className="text-lg font-semibold mb-4">Cost Distribution by Group Size</h3>
                    {hasEnoughDataForBoxPlot ? (
                      <BoxPlot data={venue.submissions} />
                    ) : (
                      <div className="text-center text-muted-foreground">
                        Need at least {MIN_SUBMISSIONS_FOR_BOXPLOT} submissions for meaningful cost distribution analysis.
                        <br />
                        Currently have {submissionCount} submission{submissionCount !== 1 ? 's' : ''}.
                      </div>
                    )}
                    {venue.submissions?.length > 0 && (
                      <div className="text-xs text-muted-foreground mt-2 text-center">
                        Box shows 25th to 75th percentile • Line shows median • Whiskers show min/max
                      </div>
                    )}
                  </CardContent>
                </Card>

                {hasEnoughDataForCharts && costPerPersonData.length > 0 && (
                  <Card>
                    <CardContent className="pt-6">
                      <h3 className="text-lg font-semibold mb-4">Cost per Person</h3>
                      <div className="h-[300px]">
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={costPerPersonData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip 
                              formatter={(value) => `$${value.toLocaleString()}`}
                              contentStyle={{
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '8px'
                              }}
                            />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="Cost per Person"
                              stroke="#3b82f6"
                              strokeWidth={2}
                              dot={{ r: 4 }}
                              activeDot={{ r: 8 }}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </CardContent>
                  </Card>
                )}

                <Card>
                  <CardContent className="pt-6">
                    <h3 className="text-lg font-semibold mb-4">Popular Event Times</h3>
                    <EventTimesHeatmap submissions={venue.submissions} />
                  </CardContent>
                </Card>

                <Card>
                  <CardContent className="pt-6">
                    <h3 className="text-lg font-semibold mb-4">Average Cost per Person</h3>
                    <CostHeatmap submissions={venue.submissions} />
                  </CardContent>
                </Card>

                <Card>
                  <CardContent className="pt-6">
                    <h3 className="text-lg font-semibold mb-4">How Far in Advance People Book</h3>
                    <LeadTimeChart submissions={venue.submissions} />
                  </CardContent>
                </Card>
              </>
            ) : (
              <Card>
                <CardContent className="pt-6 text-center text-muted-foreground">
                  <p className="mb-2">Not enough data for detailed analysis yet.</p>
                  <p className="text-sm">Need at least {MIN_SUBMISSIONS_FOR_CHARTS} submissions to show trends.</p>
                </CardContent>
              </Card>
            )}
          </div>
        </div>

        {venue.website && (
          <div className="flex justify-end mt-6">
            <a
              href={venue.website}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors"
            >
              Visit Website
              <ExternalLink className="h-4 w-4" />
            </a>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VenueDetailsModal;