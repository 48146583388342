import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { searchVenues, formatCurrency } from '../services/venueService';
import { EVENT_CATEGORIES } from '../constants/eventCategories';
import { API_BASE_URL } from '../services/venueService';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Checkbox } from "./ui/checkbox";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Alert, AlertDescription } from "./ui/alert";
import { Loader2 } from "lucide-react";

const SubmissionForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    venueName: '',
    venueAddress: '',
    placeId: '',
    guestCount: '',
    eventDate: '',
    dateBooked: new Date().toISOString().split('T')[0],
    eventStartTime: '',
    eventEndTime: '',
    totalCost: '',
    upfrontPaymentRequired: false,
    paymentType: 'none',
    depositAmount: '',
    cancellationPolicy: '',
    comments: '',
    eventType: '',
    location: '',
    neighborhood: '',
    lat: '',
    lng: '',
    category: '',
    website: '',
    phone: ''
  });

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Custom Select Styles with Tailwind-compatible colors
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value.isExisting ? 'rgb(239 246 255)' : provided.backgroundColor,
      padding: '0.5rem 0.75rem',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: state.data.value.isExisting ? 'rgb(219 234 254)' : 'rgb(243 244 246)'
      }
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: 'rgb(75 85 99)',
      fontSize: '0.875rem',
      fontWeight: 600,
      padding: '0.5rem 0.75rem',
      backgroundColor: 'rgb(249 250 251)'
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '2.5rem',
      borderColor: 'rgb(209 213 219)',
      '&:hover': {
        borderColor: 'rgb(156 163 175)'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0.125rem 0.5rem'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgb(156 163 175)'
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px'
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '0.125rem 0',
      fontSize: '0.875rem',
      color: 'rgb(17 24 39)'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 50
    })
  };

  // Custom Option Components
  const CustomOption = ({ children, ...props }) => (
    <components.Option {...props}>
      <div className="py-1">
        <div className="text-sm font-medium">{props.data.value.venueName}</div>
        <div className="text-xs text-gray-500 truncate">{props.data.value.address}</div>
      </div>
    </components.Option>
  );

  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <span className="text-sm font-medium">
        {props.data.value.venueName}
      </span>
    </components.SingleValue>
  );

  const loadVenueOptions = async (inputValue) => {
    if (inputValue.length < 2) return [];
    
    try {
      const { existing, suggestions } = await searchVenues(inputValue);
      
      return [
        {
          label: 'Existing Venues',
          options: existing
        },
        {
          label: 'Venues',
          options: suggestions
        }
      ];
    } catch (error) {
      console.error('Error loading venues:', error);
      return [];
    }
  };

  const handleVenueSelect = async (selection) => {
    if (!selection) {
      setFormData(prev => ({
        ...prev,
        placeId: '',
        venueName: '',
        venueAddress: '',
        neighborhood: '',
        location: '',
        lat: '',
        lng: '',
        category: '',
        website: '',
        phone: ''
      }));
      return;
    }
  
    setLoading(true);
    try {
      if (selection.value.isExisting) {
        setFormData(prev => ({
          ...prev,
          placeId: selection.value.placeId,
          venueName: selection.value.venueName,
          venueAddress: selection.value.address,
          neighborhood: selection.value.neighborhood,
          location: selection.value.location,
          lat: selection.value.lat,
          lng: selection.value.lng,
          category: selection.value.category,
          website: selection.value.website,
          phone: selection.value.phone
        }));
      } else {
        const response = await fetch(`${API_BASE_URL}/place-details/${selection.value.placeId}`);
        if (!response.ok) throw new Error('Failed to fetch place details');
        const placeDetails = await response.json();
        
        setFormData(prev => ({
          ...prev,
          placeId: selection.value.placeId,
          venueName: placeDetails.name,
          venueAddress: placeDetails.formatted_address,
          neighborhood: extractNeighborhood(placeDetails.address_components),
          location: extractLocality(placeDetails.address_components),
          lat: placeDetails.geometry?.location?.lat,
          lng: placeDetails.geometry?.location?.lng,
          website: placeDetails.website || '',
          phone: placeDetails.formatted_phone_number || ''
        }));
      }
    } catch (error) {
      console.error('Error selecting venue:', error);
      setError('Error fetching venue details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const extractNeighborhood = (addressComponents) => {
    const neighborhood = addressComponents?.find(
      component => component.types.includes('neighborhood')
    );
    return neighborhood?.long_name || '';
  };

  const extractLocality = (addressComponents) => {
    const locality = addressComponents?.find(
      component => component.types.includes('locality')
    );
    return locality?.long_name || '';
  };

  const handleChange = (name, value) => {
    setFormData(prev => {
      const newData = { ...prev, [name]: value };
      
      if (name === 'eventStartTime' && value) {
        const startDate = new Date(`${formData.eventDate}T${value}`);
        startDate.setHours(startDate.getHours() + 3);
        const endTime = startDate.toTimeString().slice(0, 5);
        return { ...newData, eventEndTime: endTime };
      }

      if ((name === 'totalCost' || name === 'guestCount') && newData.totalCost && newData.guestCount) {
        newData.perPersonCost = Math.round(Number(newData.totalCost) / Number(newData.guestCount));
      }
      
      return newData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Basic form validation
    if (!formData.placeId || !formData.eventType || !formData.guestCount || 
        !formData.eventDate || !formData.eventStartTime || !formData.totalCost) {
      setError('Please fill in all required fields');
      return;
    }

    setSubmitLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/submit-venue`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error('Submission failed');

      setSuccess('Thank you for your submission! Redirecting to venue list...');
      setTimeout(() => navigate('/'), 2000);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to submit venue. Please try again.');
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="container max-w-4xl mx-auto py-4 px-4 md:py-8 md:px-6">
      <Card>
        <CardHeader className="space-y-1 px-4 md:px-6">
          <CardTitle className="text-xl md:text-2xl font-display">Submit Venue Details</CardTitle>
        </CardHeader>
        <CardContent className="px-4 md:px-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            
            {/* Venue Selection */}
            <div className="space-y-2">
              <Label className="font-medium">Search for Venue *</Label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadVenueOptions}
                onChange={handleVenueSelect}
                isLoading={loading}
                isClearable
                styles={customStyles}
                placeholder="Search for existing venues or enter a new one..."
                noOptionsMessage={({ inputValue }) => 
                  inputValue.length < 2 
                    ? "Type to search..." 
                    : "No venues found"
                }
                components={{
                  Option: CustomOption,
                  SingleValue: CustomSingleValue,
                  DropdownIndicator: null,
                  IndicatorSeparator: null
                }}
                className="react-select-container"
                classNamePrefix="react-select"
              />
              {formData.placeId && (
                <div className="mt-2 text-sm text-gray-600">
                  <p className="mb-1">{formData.venueAddress}</p>
                  {formData.neighborhood && (
                    <p className="mb-0">Neighborhood: {formData.neighborhood}</p>
                  )}
                </div>
              )}
            </div>

            {/* Event Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <Label htmlFor="guestCount">Number of Guests *</Label>
                <Input
                  id="guestCount"
                  type="number"
                  inputMode="numeric" // This triggers numeric keyboard on mobile
                  pattern="[0-9]*"   // Also helps with numeric keyboard
                  required
                  min="1"
                  value={formData.guestCount}
                  onChange={(e) => handleChange('guestCount', e.target.value)}
                  className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="eventType">Event Type *</Label>
                <Select
                  value={formData.eventType}
                  onValueChange={(value) => handleChange('eventType', value)}
                >
                  <SelectTrigger id="eventType">
                    <SelectValue placeholder="Select event type" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(EVENT_CATEGORIES).map(category => (
                      <SelectItem key={category.value} value={category.value}>
                        {category.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {formData.eventType && (
                  <p className="text-sm text-gray-500">
                    {EVENT_CATEGORIES[formData.eventType]?.description}
                  </p>
                )}
              </div>
            </div>

            {/* Date and Time */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="space-y-2">
                <Label htmlFor="eventDate">Event Date *</Label>
                <Input
                  id="eventDate"
                  type="date"
                  required
                  value={formData.eventDate}
                  onChange={(e) => handleChange('eventDate', e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="dateBooked">Date Booked *</Label>
                <Input
                  id="dateBooked"
                  type="date"
                  required
                  max={formData.eventDate || undefined}
                  value={formData.dateBooked}
                  onChange={(e) => handleChange('dateBooked', e.target.value)}
                />
                {formData.dateBooked && formData.eventDate && (
                  <p className="text-sm text-gray-500">
                    {(() => {
                      const days = Math.ceil((new Date(formData.eventDate) - new Date(formData.dateBooked)) / (1000 * 60 * 60 * 24));
                      return `${days} ${days === 1 ? 'day' : 'days'} in advance`;
                    })()}
                  </p>
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="startTime">Start Time *</Label>
                <Input
                  id="startTime"
                  type="time"
                  required
                  step="300" // 5 minutes in seconds
                  value={formData.eventStartTime}
                  onChange={(e) => handleChange('eventStartTime', e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="endTime">End Time *</Label>
                <Input
                  id="endTime"
                  type="time"
                  required
                  step="300" // 5 minutes in seconds
                  value={formData.eventEndTime}
                  onChange={(e) => handleChange('eventEndTime', e.target.value)}
                />
              </div>
            </div>

            {/* Cost Information */}
            <div className="space-y-2">
              <Label htmlFor="totalCost" className="flex items-baseline gap-2">
                Total Cost (USD) *
                <span className="text-sm font-normal text-gray-500">
                  Enter either the reservation fee or estimated total spend
                </span>
              </Label>
              <Input
                id="totalCost"
                type="number"
                inputMode="decimal" // Allows decimals for cost
                pattern="[0-9]*\.?[0-9]*"
                required
                min="0"
                step="0.01"
                value={formData.totalCost}
                onChange={(e) => handleChange('totalCost', e.target.value)}
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              {formData.totalCost && formData.guestCount && (
                <p className="text-sm text-gray-500">
                  Estimated cost per person: {formatCurrency(formData.totalCost / formData.guestCount)}
                </p>
              )}
            </div>

            {/* Payment Details */}
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="upfrontPayment"
                  checked={formData.upfrontPaymentRequired}
                  onCheckedChange={(checked) => 
                    handleChange('upfrontPaymentRequired', checked)
                  }
                />
                <Label htmlFor="upfrontPayment">Upfront Payment Required</Label>
              </div>

              {formData.upfrontPaymentRequired && (
                <div className="ml-6 space-y-4">
                  <RadioGroup
                    value={formData.paymentType}
                    onValueChange={(value) => handleChange('paymentType', value)}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="partial" id="partial" />
                      <Label htmlFor="partial">Partial Deposit</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="full" id="full" />
                      <Label htmlFor="full">Full Amount</Label>
                    </div>
                  </RadioGroup>

                  {formData.paymentType === 'partial' && (
                    <div className="space-y-2">
                      <Label htmlFor="depositAmount">Deposit Amount (USD)</Label>
                      <Input
                        id="depositAmount"
                        type="number"
                        min="0"
                        step="0.01"
                        value={formData.depositAmount}
                        onChange={(e) => handleChange('depositAmount', e.target.value)}
                        className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Additional Information */}
            <div className="space-y-2">
              <Label htmlFor="cancellationPolicy">Cancellation Policy *</Label>
              <Textarea
                id="cancellationPolicy"
                required
                value={formData.cancellationPolicy}
                onChange={(e) => handleChange('cancellationPolicy', e.target.value)}
                className="min-h-[100px]"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="comments">Additional Comments</Label>
              <Textarea
                id="comments"
                value={formData.comments}
                onChange={(e) => handleChange('comments', e.target.value)}
                className="min-h-[100px]"
              />
            </div>

            {success && (
              <Alert className="bg-green-50 border-green-200">
                <AlertDescription className="text-green-800">{success}</AlertDescription>
              </Alert>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={submitLoading}
            >
              {submitLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Submitting...
                </>
              ) : (
                'Submit Venue Details'
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubmissionForm;