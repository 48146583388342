import React from 'react';

function AboutPage() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
<h1 className="text-3xl font-bold mb-6">About Us - groups.fyi</h1>
<p className="mb-6">
    groups.fyi was born from a familiar frustration: while apps have made dining out and activities for small groups effortless, organizing larger social gatherings remains a challenge.
  </p>
  
  <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
  <p className="mb-6">
    We're here to solve the "too big for a regular reservation, too small for an event planner" dilemma. Our mission is to make planning casual group gatherings straightforward and transparent by sharing real experiences from people just like you.
  </p>
  <h2 className="text-2xl font-semibold mb-4">What We Help You Plan</h2>
  <ul className="list-disc pl-6 mb-6">
    <li className="mb-2">Group dinners with prix fixe menus or sharing options</li>
    <li className="mb-2">Bar areas and semi-private spaces for celebrations</li>
    <li className="mb-2">Reserved spots for watching games with friends</li>
    <li className="mb-2">Birthday parties, reunions, and milestone gatherings</li>
    <li className="mb-2">Team outings and casual work events</li>
  </ul>
  
  <h2 className="text-2xl font-semibold mb-4">What Sets Us Apart</h2>
  <ul className="list-disc pl-6 mb-6">
    <li className="mb-2"><strong>Community-Driven Data:</strong> Access actual costs, requirements, and insider tips from recent gatherings.</li>
    <li className="mb-2"><strong>Perfect Size Focus:</strong> We specialize in those tricky 8-30 person gatherings that are too big for standard reservations but don't need full event planning.</li>
    <li className="mb-2"><strong>Skip the Forms:</strong> Get pricing and policies upfront without endless inquiry submissions.</li>
    <li className="mb-2"><strong>Details That Matter:</strong> Find essential information like deposit requirements, pre-order needs, and space configurations.</li>
  </ul>
  
  <h2 className="text-2xl font-semibold mb-4">Our Vision</h2>
  <p className="mb-6">
    Today, we're bringing transparency to group gathering costs and requirements. Tomorrow, we're building a platform that makes securing the perfect space for your group as simple as booking a table for two. No more endless email chains, quote requests, or surprise fees.
  </p>
  
  <h2 className="text-2xl font-semibold mb-4">Join Our Community</h2>
  <p className="mb-6">
    Found the perfect spot for your recent gathering? Share your experience and help others skip the hassle of planning. Your insights could save someone hours of research.
  </p>
  
  <p className="font-italic">
    Let's make gathering easier, one group at a time.
  </p>
  
  <p className="mt-4 font-semibold">
    –– The groups.fyi Team
  </p>
</div>
  );
}

export default AboutPage;