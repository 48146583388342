import React from 'react';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, Tooltip, Line } from 'recharts';

const BoxPlot = ({ data }) => {
  const calculateStats = (values) => {
    const sorted = values.sort((a, b) => a - b);
    const q1Index = Math.floor(sorted.length * 0.25);
    const q3Index = Math.floor(sorted.length * 0.75);
    return {
      min: sorted[0],
      max: sorted[sorted.length - 1],
      median: sorted[Math.floor(sorted.length * 0.5)],
      q1: sorted[q1Index],
      q3: sorted[q3Index]
    };
  };

  const processedData = Object.entries(data.reduce((acc, submission) => {
    const range = Math.floor(submission.guestCount / 10) * 10;
    const key = `${range}-${range + 9}`;
    if (!acc[key]) {
      acc[key] = { costs: [], range };
    }
    acc[key].costs.push(submission.totalCost);
    return acc;
  }, {}))
    .sort(([a], [b]) => parseInt(a) - parseInt(b))
    .map(([range, { costs }]) => {
      const stats = calculateStats(costs);
      return {
        name: `${range} guests`,
        min: stats.min,
        q1: stats.q1,
        median: stats.median,
        q3: stats.q3,
        max: stats.max,
        count: costs.length
      };
    });

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart 
          data={processedData}
          margin={{ 
            top: 20, 
            right: 30, 
            left: 60, 
            bottom: 80 // Increased bottom margin
          }}
        >
          <XAxis 
            dataKey="name"
            angle={-45}
            textAnchor="end"
            height={80} // Increased height for labels
            tick={{ fontSize: 12 }} // Adjusted font size
            interval={0}
          />
          <YAxis 
            label={{ 
              value: 'Cost ($)', 
              angle: -90, 
              position: 'insideLeft',
              offset: -45
            }}
            tickFormatter={(value) => `$${value.toLocaleString()}`}
            tick={{ fontSize: 12 }} // Adjusted font size
          />
          <Tooltip
            formatter={(value) => `$${value.toLocaleString()}`}
            labelFormatter={(label) => `Group Size: ${label}`}
            contentStyle={{
              backgroundColor: 'white',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              fontSize: '12px'
            }}
          />
          <Bar
            dataKey="q3"
            stackId="box"
            fill="#4361EE"
            opacity={0.3}
            name="75th percentile"
          />
          <Bar
            dataKey="median"
            stackId="box"
            fill="#4361EE"
            stroke="#4361EE"
            strokeWidth={2}
            name="Median"
          />
          <Line
            type="monotone"
            dataKey="max"
            stroke="#4361EE"
            strokeWidth={2}
            dot={{ fill: '#4361EE', r: 4 }}
            name="Maximum"
          />
          <Line
            type="monotone"
            dataKey="min"
            stroke="#4361EE"
            strokeWidth={2}
            dot={{ fill: '#4361EE', r: 4 }}
            name="Minimum"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BoxPlot;