import React, { useMemo } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const LeadTimeChart = ({ submissions }) => {
  const leadTimeData = useMemo(() => {
    if (!submissions?.length) return [];
    
    const data = submissions
      .filter(sub => sub.dateBooked && sub.eventDate)
      .map(sub => {
        const eventDate = new Date(sub.eventDate);
        const bookDate = new Date(sub.dateBooked);
        return Math.ceil((eventDate - bookDate) / (1000 * 60 * 60 * 24));
      })
      .reduce((acc, days) => {
        if (!acc[days]) {
          acc[days] = { days, count: 0 };
        }
        acc[days].count++;
        return acc;
      }, {});

    return Object.values(data).sort((a, b) => a.days - b.days);
  }, [submissions]);

  if (!submissions?.length || leadTimeData.length === 0) return null;

  return (
    <div className="h-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={leadTimeData}
          margin={{ top: 20, right: 30, left: 10, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" opacity={0.5} />
          <XAxis 
            dataKey="days"
            tickFormatter={(days) => `${days} ${days === 1 ? 'day' : 'days'}`}
            angle={-45}
            textAnchor="end"
            height={60}
          />
          <YAxis hide={true} />
          <Tooltip
            formatter={(value) => [`${value} ${value === 1 ? 'booking' : 'bookings'}`]}
            labelFormatter={(days) => `${days} ${days === 1 ? 'day' : 'days'} in advance`}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#4361EE"
            fill="#4361EE"
            fillOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LeadTimeChart;