import React, { useMemo } from 'react';

const CostHeatmap = ({ submissions }) => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const timeSlots = ['Day', 'Evening'];
  
  const heatmapData = useMemo(() => {
    const costs = {};
    const counts = {};
    daysOfWeek.forEach(day => {
      costs[day] = { 'Day': [], 'Evening': [] };
      counts[day] = { 'Day': 0, 'Evening': 0 };
    });

    submissions?.forEach(sub => {
      const eventDate = new Date(sub.eventDate);
      const dayIndex = eventDate.getDay() === 0 ? 6 : eventDate.getDay() - 1;
      const day = daysOfWeek[dayIndex];
      const hour = parseInt(sub.eventStartTime?.split(':')[0]) || 12;
      const timeSlot = hour >= 17 ? 'Evening' : 'Day';
      
      const costPerPerson = sub.totalCost / sub.guestCount;
      costs[day][timeSlot].push(costPerPerson);
      counts[day][timeSlot]++;
    });

    const averages = {};
    let maxAvg = 0;
    daysOfWeek.forEach(day => {
      averages[day] = {
        'Day': costs[day]['Day'].length > 0 
          ? costs[day]['Day'].reduce((a, b) => a + b, 0) / costs[day]['Day'].length 
          : 0,
        'Evening': costs[day]['Evening'].length > 0 
          ? costs[day]['Evening'].reduce((a, b) => a + b, 0) / costs[day]['Evening'].length 
          : 0
      };
      maxAvg = Math.max(maxAvg, averages[day]['Day'], averages[day]['Evening']);
    });

    return { averages, maxAvg, counts };
  }, [submissions]);

  const getColorIntensity = (value, maxValue) => {
    if (maxValue === 0) return 'rgba(79, 70, 229, 0.1)'; // indigo-600 with low opacity
    const intensity = Math.max(0.1, value / maxValue);
    return `rgba(79, 70, 229, ${intensity})`; // indigo-600 with varying opacity
  };

  if (!submissions?.length) return null;

  return (
    <div className="space-y-2">
      <div className="relative">
        {/* Header row */}
        <div className="grid grid-cols-7 gap-1 md:gap-2 mb-2 ml-16 md:ml-20">
          {daysOfWeek.map(day => (
            <div key={day} className="text-sm font-medium text-center">
              {day}
            </div>
          ))}
        </div>

        {/* Time slots and cells */}
        <div className="space-y-2">
          {timeSlots.map(timeSlot => (
            <div key={timeSlot} className="flex items-center">
              <div className="absolute left-0 text-sm font-medium text-right pr-2 md:pr-4 w-16 md:w-20">
                {timeSlot}
              </div>
              <div className="grid grid-cols-7 gap-1 md:gap-2 flex-1 ml-16 md:ml-20">
                {daysOfWeek.map(day => (
                  <div
                    key={`${day}-${timeSlot}`}
                    className="aspect-square"
                    style={{
                      backgroundColor: getColorIntensity(
                        heatmapData.averages[day][timeSlot],
                        heatmapData.maxAvg
                      ),
                      borderRadius: '4px',
                      minHeight: '32px',
                      maxHeight: '48px'
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="text-xs text-center text-gray-500 mt-4">
        Darker color indicates higher cost per person
      </div>
    </div>
  );
};

export default CostHeatmap;